import React, {useEffect, useState} from 'react'
import unzipMenu from '../../Context/unzipMenu'
import axios from 'axios'
import {AutoMenuWithRouter} from '../../react/AutoMenu'
import applyDiscount from '../../Context/applyDiscount'
import './index.scss'
import './altStyleA.scss'
export default function () {
  let menuURL = `https://afoodapart.com/api/menu/pablitosworld`
  const [menu, setMenu] = useState(null)
  useEffect(() => {
    axios.get(menuURL)
    .then((response) => {
      // console.log("response:", unzipMenu(response.data.menu))
      setMenu(
        applyDiscount(
          unzipMenu(
            response.data.menu
          )
        )
        // applyDiscount(JSON.parse(response.data.fields.shop.stringValue).menu)
      )
    })
  }, [0])

  console.log('menu:', menu)
  return(
    <div className="ordering-page">

      <div className="about-page">
        <article className="coming-soon">
          <h1>Burbank Location Coming Soon!</h1>
          <p>
            Please check out Burbank's menu preview below!
          </p>
        </article>
      </div>
     
     <AutoMenuWithRouter 
     menu={menu}
     disableOrder={true}
     subMenuFilter={subMenuFilter}
     altStyleA ></AutoMenuWithRouter>

    </div>
  )
}


function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return false}
  if (tag.indexOf('hidden') !== -1) { return false}
  if (tag.indexOf('hamburguetza') !== -1) { return true}
  return false
}