import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Catering from './Catering'
import About from './About'
import Contact from './Contact'
import QRMenu from './QRMenu'
import Kitchen from './Kitchen'
import Bakery from './Bakery'
import Merch from './Merch'
import Form from './Form'
import './index.scss'
import './altStyleA.scss'
import './ThemeToggle/theme_night.scss'
import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'
import makeCloudinaryUrlForPhoto from '../../react/makeCloudinaryUrlForPhoto'
import Burbank from './Burbank';
import urls from './urls'
import AutoMenu from '../../react/AutoMenu'
import AutoMenuCustom from './AutoMenu'

import {injectShopContainer} from '../../Context/ShopData'


import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
export {CartBar as StickyTopBar}

const wingFlavors = ["Chicken Parmesan", "Hot Pastrami", "Philly Steak", "Chicken Pesto", "Meatball", "BBQ Chicken", "Cold Cuts",]

const urlMappings = urls.filter(({content, redirect}) => {
  return !!content || !!redirect
}).map(({origin, content, redirect}) => {
  const pageObj = { path: origin, unlisted: true }
  if (content) {
    pageObj.component = content
  }
  if (redirect) {
    console.log(redirect)
    pageObj.component = () => (<Redirect to={redirect}></Redirect>)
  }
  return pageObj
})


export const customOrderAppPaths = []

export const pages = [
  {path: "/", label: "Home" },
  {path: "/order", label: "Order" },
  {path: "/menu", label: "Menu", component: Menu },
  {path: "/burbank", label: "Burbank", component: Burbank },
  {path: "/house", hidden: true, notExact: true, component: QRMenu },
  // {path: "/house/:categoryId", hidden: true, component: QRMenu },
  {path: "/all", hidden: true, component: AllItems},
  {path: "/all/:id", label: "Menu", component: AllItems, hidden: true },
  // {path: "/catering", label: "Catering", component: Catering },
  // {path: "/about/the-cafe", label: "About", component: About },
  // {path: "/find-us", label: "Find Us", component: Contact },
  // {path: "/catering", label: Caterings", component: Catering },
].concat(urlMappings)

export class SiteNav extends PureComponent {
  render() {
    return (null)
    return (
    <nav aria-label="secondary" className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      {/* <ThemeToggle></ThemeToggle> */}
    </nav>
  )}
}

export default function AllItems ({match}) {
  const id = match.params.id
  return(
    <div className="landing-page-wrapper">
      <div className="">
        <div
            className="page-header">
          <div 
          className="text">
              <h1 style={{
            textAlign: 'center',
            fontWeigth: 800,
            fontSize: "1.5em"
          }}>All Menu Items</h1>
      
      
          </div>
      
          </div>
      {/*
          <p
          style={{
            textAlign: 'center',
            fontWeight: 700
          }}
          ><a href="/public/mikaza/mikaza-v2.pdf">PDF Menu</a></p>         */}
        {id ?
        <AutoMenuCustom
        categorysToShow={[id]}
        disableOrder={true}
      ></AutoMenuCustom>
        :
        <AutoMenuCustom
          disableOrder={true}
        ></AutoMenuCustom>
      }
      
      
      </div>
    </div>
  )
}

const quickLinks = [
  { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-1.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-2.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-3.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-4.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-5.jpg"}
]



function Header ({shop}) {
  console.log(shop)
  const photos = []
  shop.subMenus.forEach(({tag, products}) => {
    if (tag && tag.indexOf('hamburg') !== -1) {
      products.forEach((product) => {
        if (product.photo) {
          photos.push(makeCloudinaryUrlForPhoto(product.photo, {width: 1000, height: 1000 }))
        }
      })
    }
  })
    // menu.subMenu.forEach((item) => {
    //   console.log(item.name)
    // })

    const now = new Date()
    const day = now.getDay() // Sunday - Saturday : 0 - 6
    const hours = now.getHours() //0 to 23
    const isMorning = (hours < 16)

    const {themeName} = useContext(ThemeContext)
    const heroUrl = (themeName === 'dark') ?
      "https://afag.imgix.net/romancing-the-bean-cafe/espress-dark.jpg?w=500"
      :
     "https://afag.imgix.net/romancing-the-bean-cafe/espresso-light.jpg?w=500"
    return (
      <div className="landing-page-wrapper">

        <div className="home-page-blocks">

        <div className="image-block">
            <h1><img src={"https://afag.imgix.net/pablitos-world/hamburguetza-1.png?w=1000&auto=format"} alt="Hamburguetza" /></h1>
        </div>

        {/* <div className="image-grid">
          {photos.map((photo) => {
            return (
              <div className="image-grid-image"><img src={photo} alt="smashed burger" /></div>
            )
          }
          )}
        </div> */}

        <div className="image-block"> </div>
          

          {/* <div className="image-block">
            <img src="https://afag.imgix.net/the-pizzeria-factory/hero.jpg?w=2000&h=1000&fit=crop&auto=format" alt="pizza" />
          </div> */}

          <div className="text-paragraph">
            <p>Discover bold flavors at Hamburguetza, NoHo's home for smashed Wagyu burgers. Juicy, crispy-edged patties meet fresh toppings and signature sauces for a burger experience like no other. Taste the Wagyu difference today!</p>
                  

          </div>

          {/* <div className="text-block">
          <p>We Speak 3 Languages:</p>
        <p>English: OK, 普通话: OK, 한국어: OK</p>
          </div> */}

          <div className="text-block">
            <div className="online-order-card">
              <h2>Order<br/>Pickup / Delivery</h2>
              <div className="location-buttons">
              {/* <p><Link to={{pathname: "/menu", state: {startAtTop: true}}}   className="menu-button">View Menu</Link></p> */}
                <Link to={{pathname: "/order", state: {startAtTop: true}}} className="order-button">North Hollywood</Link>
                <a href="https://pablitostacos.com/hamburguetzaburbank" className="order-button">Burbank</a>
                {/* <a href="https://pablitostacos.com/hamburguetza" className="order-button">North Hollywood</a> */}
                {/* <Link to="#" className="order-button soon">Delivery</Link> */}
              </div>
            </div>
          
          </div>


          <div className="text-block">
              <div className="address">
              {/* <h2>ADDRESS</h2> */}
              <p><a href="https://www.google.com/maps/place/5600+Vineland+Ave,+North+Hollywood,+CA+91601/@34.1724395,-118.372472,17z/data=!3m1!4b1!4m6!3m5!1s0x80c295ecd3d725a3:0x9552fdc2c95bcefa!8m2!3d34.1724395!4d-118.3698971!16s%2Fg%2F11bw3yghlg?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D">
              5600 Vineland Ave
                </a></p>
                
              <p>North Hollywood, CA 91601</p>
              {/* <h2>PHONE</h2> */}
              <p><a href="tel:+18189668534">(818) 966-8534</a></p>
              {/* <h2>HOURS</h2> */}
              <p>Sun,Mon,Tue,Wed,Thu</p>
              <p>5pm - 12am</p>
              <p>Fri,Sat</p>
              <p>5pm - 1am</p>
              {/* <p>*delivery ends at 9:15pm</p> */}
            </div>
          </div>


          {/* <div className="text-paragraph">
            <p>
            我们专注于提供可定制的火锅和麻辣香锅选择，邀请您根据自己的口味偏好来定制您的盛宴。尽情享受我们的街头风格烤串和经典中式菜肴，完成一次完整的美食之旅。加入我们，开启一次大胆而激动人心的用餐冒险，每一口都是火辣的庆祝。
            </p>

          </div> */}

          {/* <div className="image-block">
            <img src="https://afag.imgix.net/the-pizzeria-factory/sub.jpg?w=1500&auto=format" alt="wisubsngs" />
          </div> */}

          {/* <div className="text-block flavors">
            <h2>Subs</h2>
            
            {wingFlavors.map((flavor) => {
              return(
                <span className="flavor">{flavor}</span>
              )
            })}
            
          </div> */}
{/* 
          <div className="image-block">
            <img src="https://afag.imgix.net/the-pizzeria-factory/pizza.jpg?w=1500&auto=format" alt="wisubsngs" />
          </div> */}

          {/* <div className="text-block flavors">
            <h2>Pizzas</h2>
            
            {wingFlavors.map((flavor) => {
              return(
                <span className="flavor">{flavor}</span>
              )
            })}
            
          </div> */}

          {/* <div className="image-block">
            <img src="https://images.unsplash.com/photo-1532634922-8fe0b757fb13?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2344&h=1000&q=80" alt="wings" />
          </div> */}
          

          {/* <div className="text-block catering">
            <h2>CATERING</h2>
            <p>Make your next event a hit with our top-notch catering service. Offering a mouth-watering selection of pizzas, subs, and wings, we've got all the flavors to keep your guests talking. Convenient, delicious, and always a crowd-pleaser—let us handle the food so you can focus on the fun!</p>
          </div>

          <div className="image-block">
            <img src="https://afag.imgix.net/the-pizzeria-factory/tshirt-1.png?w=1200&auto=format" alt="tshirt" />
          </div>


          <div className="text-block catering">
            <h2>MERCH</h2>
            <p>Heat up your style with our soon-to-launch T-shirt merch! Show off your love for pizza with our vibrant, sauce-inspired designs. They're more than just a shirt—they're a badge of honor for true heat enthusiasts. Keep an eye out, because it's about to get even hotter in here!</p>
          </div> */}

          
          <Form></Form>
          
{/* 
          <div className="text-block ordering">
            <div className="text">
              <p><Link to={{pathname: "/menu", state: {startAtTop: true}}}   className="menu-button">View Menu →</Link></p>
              <p><Link to={{pathname: "/order", state: {startAtTop: true}}} className="order-button">Order Pickup / Delivery →</Link></p>
            </div>
          </div> */}

        </div>
       
        {/* <Form></Form> */}

       



       







      </div>
    );

}

const injectedHeader = injectShopContainer(Header)
export {injectedHeader as Header}


function Menu () {
  return (
    <div className="ordering-page">
        {/* <div className="ordering-header">
          
        
        </div> */}
        <AutoMenu altStyleA subMenuFilter={subMenuFilter}></AutoMenu>
      </div>
  )
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-page">
        {/* <div className="ordering-header">
        
        
        </div> */}
        <AutoMenu altStyleA subMenuFilter={subMenuFilter}></AutoMenu>
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4].indexOf(day) !== -1
  const isLunch = (
    (hours >= 11) && (hours < 22)
  )
  const isBreakfast = (
    (hours < 15)
  )

  const isPromo =     tag.indexOf('promo') !== -1 
  const isBreakfastMenu =     tag.indexOf('breakfast') !== -1 
  const isLunchMenu =     tag.indexOf('lunch') !== -1 
  const isSouthOfBorderMenu =     tag.indexOf('south-of') !== -1 
  const isDinnerMenu =     tag.indexOf('dinner') !== -1 
  const isQRCode =     tag.indexOf('qr-only') !== -1 
  
  if (isQRCode) {
    return false
  }

  if (isBreakfastMenu) {
    console.log('breakfast', hours)
    if ((hours < 16) && ([0,1,2,3,4,5,6].indexOf(day) !== -1)) {
      // no monday
      return true
    } else if ((hours >= 21) && ([0,1,2,3,4,5,6].indexOf(day) !== -1)) {
      // breakfasat preorder
      return true
    } else {
      return false
    }
  }
  if (isLunchMenu) {
    if ((hours>= 11) && (hours < 21)) {
      return true
    } else {
      return false
    }
  }
  if (isSouthOfBorderMenu) {
    if ([1,2,3,4,5].indexOf(day) !== -1) {
      
      if ((hours>= 10) && (hours < 21)) {
        return true
      } else {
        return false
      }
    } else {
      if ((hours>= 16) && (hours < 21)) {
        return true
      } else {
        return false
      }
    }
  }
  if (isDinnerMenu) {
    if ((hours >= 16) && (hours < 21)) {
      return true
    } else {
      return false
    }
  }
  if (isPromo) {return false}

  return true
}
